<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Filters @filters="setFilters" />
    <div class="col">
      <RegistrantsList :filters="filters"></RegistrantsList>
    </div>
  </Layout>
</template>

<script>
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Filters from "../live-events/filters.vue";
import RegistrantsList from "./registrants-table.vue";

/**
 * Dashboard component
 */
export default {
  mixins: [filterMixins],
  components: {
    Layout,
    PageHeader,
    Filters,
    RegistrantsList
  },
  data() {
    return {
      title: "Live Events",
      items: [
        {
          text: "Medisage",
        },
        {
          text: "Live Events",
          active: true,
        },
      ],
      filters: {},
      reload: 0,
      loading: true,
      countData: {},
      totalCountData: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // this.loading = true;
      this.getData();
      // this.loading = false;
    },
    async getData() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
            appConfig.api_base_url + "/liveEvents/count",
            {
              params: searchfiltersParams,
            }
        );
        this.countData = response.data.count[0];
        this.totalCountData = response.data.total_count[0];
      } catch (err) {
        console.log(err);
      }
    },
    setFilters(v) {
      this.filters = v;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
};
</script>